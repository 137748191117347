export default {
  mediaQueries: {
    mobileS: "(max-width: 320px)",
    mobileM: "(max-width: 375px)",
    mobileL: "(max-width: 425px)",
    tabletS: "(max-width: 525px)",
    tabletM: "(max-width: 625px)",
    tabletL: "(max-width: 768px)",
    laptop: "(max-width: 1024px)",
    laptopL: "(max-width: 1440px)",
    desktop: "(max-width: 2560px)",
  },
  colors: {
    blue50: "#e8f1fb",
    blue100: "#d1e3f6",
    blue200: "#a4c7ed",
    blue300: "#76abe5",
    blue400: "#498fdc",
    blue500: "#1b73d3",
    blue600: "#165ca9",
    blue700: "#10457f",
    blue800: "#0b2e54",
    blue900: "#05172a",

    gold50: "#fef7eb",
    gold100: "#fdefd7",
    gold200: "#fbdfaf",
    gold300: "#fad087",
    gold400: "#f8c05f",
    gold500: "#f6b037",
    gold600: "#c58d2c",
    gold700: "#946a21",
    gold800: "#624616",
    gold900: "#31230b",

    orange100: "#ffedcc",
    orange200: "#ffdb99",
    orange300: "#ffc966",
    orange400: "#ffb733",
    orange500: "#ffa500",
    orange600: "#cc8400",
    orange700: "#996300",
    orange800: "#664200",
    orange900: "#332100",

    green100: "#cce6cc",
    green200: "#99cc99",
    green300: "#66b366",
    green400: "#339933",
    green500: "#008000",
    green600: "#006600",
    green700: "#004d00",
    green800: "#003300",
    green900: "#001a00",

    navbargreen: "#24b47e",
    navbarteal: "#4f96ce",
    navbarblue: "#6772e5",
    navbardarkblue: "#4f3ef5",

    darkblue100: "#cccce8",
    darkblue200: "#9999d1",
    darkblue300: "#6666b9",
    darkblue400: "#3333a2",
    darkblue500: "#00008b",
    darkblue600: "#00006f",
    darkblue700: "#000053",
    darkblue800: "#000038",
    darkblue900: "#00001c",

    red100: "#ffcccc",
    red200: "#ff9999",
    red300: "#ff6666",
    red400: "#ff3333",
    red500: "#ff0000",
    red600: "#cc0000",
    red700: "#990000",
    red800: "#660000",
    red900: "#330000",

    grey50: "#fafafa",
    grey100: "#f4f4f5",
    grey200: "#e4e4e7",
    grey300: "#d4d4d8",
    grey400: "#a1a1aa",
    grey500: "#71717a",
    grey600: "#52525b",
    grey700: "#3f3f46",
    grey800: "#27272a",
    grey900: "#18181b",

    black: "#222",
    white: "#fff",
    redLight: "#f8d7da",
    redDark: "#842029",
    greenLight: "#d1e7dd",
    greenDark: "#0f5132",
  },
  fonts: {
    headingFont:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    bodyFont:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    smallText: "0.7em",
  },
  backgroundColor: "#fafafa",
  textColor: "#18181b",
  borderRadius: ".25rem",
  letterSpacing: "1px",
  transition: "0.3s ease-in-out all",
  maxWidth: "1020px",
  fixedWidth: "700px",

  spacer: "28px",

  shadows: {
    shadow1: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    shadow2:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    shadow3:
      " 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    shadow4:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    lightShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    darkShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
    otherShadow: "4px 4px 20px rgb(0 0 0 / 25%)",
  },
}
